import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateStaffAndAdminUser = _resolveComponent("UpdateStaffAndAdminUser")!

  return (_openBlock(), _createBlock(_component_UpdateStaffAndAdminUser, {
    redirect: `admin-staff-users`,
    "header-title": `Edit IBN Staff`,
    route: _ctx.route
  }, null, 8, ["route"]))
}